@charset "UTF-8";

@import "./variables";

//---------------------------------------------------------------
// レイアウト関連のCSS
//---------------------------------------------------------------

.l-renewal-content {

  .l-columns {
    width: 100%;

    & > .l-column {
      display: block;
    }

    @media (min-width: $media-small) {
      display: flex;
      flex-direction: row;

      &.l-multiline {
        flex-wrap: wrap;
      }
    }

    &-mobile {
      display: flex;
      flex-direction: row;

      &.l-multiline {
        flex-wrap: wrap;
      }

      & > .l-column {
        display: block;
      }

      &.l-column-1 {
        & > .l-column {
          width: 100%;
        }
      }

      &.l-column-2 {
        & > .l-column {
          width: 50%;
        }
      }


      &.l-column-3 {
        & > .l-column {
          width: 33.3%;
        }
      }
    }

    &-only-mobile {
      @media (max-width: $media-small) {
        display: flex;
        flex-direction: row;

        &.l-multiline {
          flex-wrap: wrap;
        }

        & > .l-column {
          display: block;
        }
      }
    }

    &.l-column-1 {
      & > .l-column {
        width: 100%;
      }
    }

    &.l-column-2 {
      & > .l-column {
        width: 50%;
      }
    }


    &.l-column-3 {
      & > .l-column {
        width: 33.3%;
      }
    }


    @media (max-width: $media-small) {
      &.l-column-1-mobile {
        & > .l-column {
          width: 100%;
        }
      }
      &.l-column-2-mobile {
        & > .l-column {
          width: calc(100% / 2);
        }
      }
      &.l-column-3-mobile {
        & > .l-column {
          width: calc(100% / 3);
        }
      }
    }

    @media (min-width: $media-small) {
      &.l-column-1-tablet {
        & > .l-column {
          width: 100%;
        }
      }
      &.l-column-2-tablet {
        & > .l-column {
          width: calc(100% / 2);
        }
      }
      &.l-column-3-tablet {
        & > .l-column {
          width: calc(100% / 3);
        }
      }
      &.l-column-4-tablet {
        & > .l-column {
          width: calc(100% / 4);
        }
      }
      &.l-column-5-tablet {
        & > .l-column {
          width: calc(100% / 5);
        }
      }
      &.l-column-6-tablet {
        & > .l-column {
          width: calc(100% / 6);
        }
      }
      &.l-column-7-tablet {
        & > .l-column {
          width: calc(100% / 7);
        }
      }
      &.l-column-8-tablet {
        & > .l-column {
          width: calc(100% / 8);
        }
      }
      &.l-column-9-tablet {
        & > .l-column {
          width: calc(100% / 9);
        }
      }
      &.l-column-10-tablet {
        & > .l-column {
          width: calc(100% / 10);
        }
      }
      &.l-column-11-tablet {
        & > .l-column {
          width: calc(100% / 11);
        }
      }
      &.l-column-12-tablet {
        & > .l-column {
          width: calc(100% / 12);
        }
      }
    }

  }

  .l-hidden-mobile {
    @media (max-width: $media-small) {
      display: none !important;
    }
  }

  .l-hidden-tablet {
    @media (min-width: $media-small) {
      display: none !important;
    }
  }

  .l-hidden {
    display: none !important;
  }

  .l-visibility-hidden {
    visibility: hidden;
    display: none;
  }

  .l-j-center {
    justify-content: center;

    &-tablet {
      @media (max-width: $media-small) {
        justify-content: center;
      }
    }
  }

  .l-inline-block {
    display: inline-block;
  }

  .l-center {
    text-align: center;
  }

  .l-vertical-center {
    display: flex;
    align-items: center;
  }

  .l-vertical-center-tablet {
    @media (min-width: $media-small) {
      display: flex;
      align-items: center;
    }
  }

  .l-v-align-super {
    vertical-align: super;
  }

  .l-margin-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .l-line-height-m {

    &,
    & * {
      line-height: 24px;
    }
  }

  .l-break {
    white-space: pre-wrap;
  }

  /**
    .l-w-[x]pは自動生成しているため、functionに記述
   */

  .l-w-100per {
    width: 100%;
  }

  .l-h-100per {
    height: 100%;
  }

} // .l-renewal-content
